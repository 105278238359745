import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Card } from "@material-ui/core"
import { Link } from "gatsby"
interface PartnerGridCardI {
  partner: any
}

export const PartnerGridCard: FunctionComponent<PartnerGridCardI> = ({ partner }) => {
  let logo
  try {
    logo = partner.PartnerInfo.partnershipLogo.localFile.childImageSharp.gatsbyImageData
  } catch {
    logo = null
  }
  const [shadow, setShadow] = useState(0)
  const onMouseOver = () => setShadow(15)
  const onMouseOut = () => setShadow(0)

  return (
    <Container
      elevation={partner.PartnerInfo.clickThroughToPartnerPage ? shadow : ""}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <StyledLink to={partner.PartnerInfo.clickThroughToPartnerPage ? "/partnerships/" + partner.slug : null}>
        <ImgContainer>
          <GatsbyImage
            image={logo}
            style={{ height: "auto", width: "100%" }}
            imgStyle={{ objectFit: "fit" }}
            alt={"logo for " + partner.slug}
          />
        </ImgContainer>
        <PartnerInfoBox>
          <PartnerName> {partner.title} </PartnerName>
        </PartnerInfoBox>
      </StyledLink>
    </Container>
  )
}

const Container = styled(Card)`
  border: 1px solid #e3e3e3;
  background-color: #fff;
  z-index: 16;
  max-width: 350px;
  border-top: 3px solid black;
  text-decoration: none;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: auto;
    max-width: 350px;
    min-width: 160px;
  }
  &:hover,
  &:active {
    text-decoration: none;
    //  scale: 1.01;
  }
`
const StyledLink = styled(Link)`
  z-index: 0;
  :hover {
    text-decoration: none;
  }
`
// const StyledLink = styled(Link)`   //changed from this to div to stop click through @notlive
//   :hover {
//     text-decoration: none;
//   }
// `
const ImgContainer = styled.div`
  display: flex;
  min-height: 218px;
  background-color: white;
  align-items: center;
  justify-content: center;
  z-index: 10;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    min-height: 155px;
  }
`

const PartnerInfoBox = styled.div`
  text-decoration: none;
  box-shadow: none;
  padding: 22px 30px;
  z-index: 10;
  background-color: ${props => props.theme.tints.lighterGrey};
  /* min-height: 200px; */
  min-height: 100px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    /* min-height: 250px; */
    min-height: 150px;
  }
`

const PartnerName = styled.p`
  color: black;

  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 18px;
  @media (max-width: 768px) {
    font-size: 16px;
    text-decoration: none;

    line-height: 21px;
  }
`

const PartnerDesc = styled.p`
  color: #4a4a4a;
  font-size: 18px;
  line-height: 28px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 14px;

    line-height: 24px;
  }
`
