import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { PartnerGridCard } from "./PartnerGridCard"
import MainGrid from "../style/MainGrid"
import { graphql, StaticQuery } from "gatsby"

export const PartnerGrid: FunctionComponent = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPartner {
            edges {
              node {
                id
                title
                slug
                PartnerInfo {
                  tags
                  clickThroughToPartnerPage
                  partnershipIntro
                  listingPriority
                  partnershipLogo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 400, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const partners = data.allWpPartner.edges
        const partnerNode = partners.map(v => v.node)
        const prioritySorted = partnerNode.sort((a, b) =>{
       
          return( a.PartnerInfo.listingPriority > b.PartnerInfo.listingPriority ? 1 : -1)}
        )
        
        return (
          <MainGrid>
            <Container>
              {prioritySorted.map((v, i) => {
                return <PartnerGridCard partner={v} key={"partner-grid-card-" + v.slug} />
              })}
            </Container>
          </MainGrid>
        )
      }}
    />
  )
}

const BreadCrumbContainer = styled.div`
  width: 100vw;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  z-index: 10;
`
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: 8px;
  grid-row-gap: 20px;
  margin-bottom: 50px;
  margin-top: 10px;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 20px;
    height: 100%;
    margin-bottom: 7.4rem;
  }
`

const ImgContainer = styled.div``
const Box = styled.div``
